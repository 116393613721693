<template>
  <div class="pr-2">
    <template
      v-if="
        db_line_items2 && db_line_items2.length && db_line_items2.length > 0
      "
    >
      <div class="my-3 text-h5 color-custom-blue">Line Item</div>
      <div style="max-width: 100%; overflow-x: scroll" v-if="false">
        <table
          class="table fixed-td"
          cellspacing="0"
          cellpadding="0"
          style="width: 2300px"
        >
          <thead>
            <tr class="grey lighten-4" style="border-bottom: solid 1px #e4e3e3">
              <th class="color-custom-blue text-center" style="width: 2%">#</th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Brand
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Model
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Part
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Product
              </th>
              <th class="color-custom-blue text-center" style="width: 4%">
                Currency
              </th>
              <th class="color-custom-blue text-center" style="width: 4%">
                Cost
              </th>
              <th class="color-custom-blue text-center" style="width: 3%">
                FLT
              </th>
              <th class="color-custom-blue text-center" style="width: 4%">
                XR
              </th>
              <th class="color-custom-blue text-center" style="width: 4%">
                GEAR
              </th>
              <th class="color-custom-blue text-center" style="width: 4%">
                LAMP
              </th>
              <th class="color-custom-blue text-center" style="width: 3%">
                ACC
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Unit Cost
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Markup %
              </th>
              <th class="color-custom-blue text-center" style="width: 6%">
                Unit Selling
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                REQ. QTY
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Pro. QTY
              </th>
              <th class="color-custom-blue text-center" style="width: 3%">
                UOM
              </th>
              <th class="color-custom-blue text-center" style="width: 7%">
                Pro. Unit Sell.
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Total Cost
              </th>
              <th class="color-custom-blue text-center" style="width: 5%">
                Total Sell.
              </th>
              <th class="color-custom-blue text-right" style="width: 6%">
                % Total Sales
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, index) in db_line_items2">
              <tr :key="index" class="line-item-drag">
                <td colspan="22">
                  <div class="font-weight-600">{{ row?.group_name }}</div>
                  <div class="font-weight-600">
                    {{ row?.group_item_name }}
                  </div>
                </td>
              </tr>
              <tr v-for="(item, cindex) in row?.item_feature" :key="cindex">
                <td class="text-center" style="width: 2%">{{ item.id }}.</td>
                <td class="text-center" style="width: 5%">{{ item.brand }}</td>
                <td class="text-center" style="width: 5%">{{ item.model }}</td>
                <td class="text-center" style="width: 5%">
                  {{ item.part_name }}
                </td>
                <td class="text-center" style="width: 5%">
                  {{ item.product_type }}
                </td>
                <td class="text-center" style="width: 4%">
                  {{ item.currency }}
                </td>
                <td class="text-center" style="width: 4%">{{ item.cost }}</td>
                <td class="text-center" style="width: 3%">{{ item.flt }}</td>
                <td class="text-center" style="width: 4%">{{ item.xr }}</td>
                <td class="text-center" style="width: 4%">{{ item.gear }}</td>
                <td class="text-center" style="width: 4%">{{ item.lamp }}</td>
                <td class="text-center" style="width: 3%">{{ item.acc }}</td>
                <td class="text-center" style="width: 5%">
                  {{ item.unit_cost }}
                </td>
                <td class="text-center" style="width: 5%">{{ item.markup }}</td>
                <td class="text-center" style="width: 6%">
                  {{ item.unit_selling }}
                </td>
                <td class="text-center" style="width: 5%">
                  {{ item.req_qty }}
                </td>
                <td class="text-center" style="width: 5%">
                  {{ item._pro_req_qty }}
                </td>
                <td class="text-center" style="width: 3%">{{ item.uom }}</td>
                <td class="text-center" style="width: 7%">
                  {{ item.pro_unit_selling }}
                </td>
                <td class="text-center" style="width: 5%">
                  {{ item.total_cost }}
                </td>
                <td class="text-center" style="width: 5%">
                  {{ item.total_selling }}
                </td>
                <td class="text-center" style="width: 6%">{{ item.total }}</td>
              </tr>
              <tr
                v-if="row.item_feature.length !== 0"
                :key="`line-item-drag-${index}`"
              >
                <td colspan="17" class="text-end font-weight-600">Total</td>
                <td class="text-center">{{ row?.total_cost_value }}</td>
                <td class="text-center">{{ row?.discounted_total_selling }}</td>
                <td class="text-center font-weight-600">
                  {{ row?.total_selling }}
                </td>
                <td class="text-center">{{ row?.total_margin }}%</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div style="max-width: 100%; overflow-x: scroll">
        <div class="" style="width: 2300px">
          <div
            class="d-flex grey lighten-4 px-0 costing-item-header"
            style="border-bottom: solid 1px #e4e3e3 !important"
          >
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 2%"
            >
              #
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Brand
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Model
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Part
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Product
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              Currency
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              FLT
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              XR
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              GEAR
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              LAMP
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              ACC
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Unit Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Markup %
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 6%"
            >
              Unit Selling
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              REQ. QTY
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Pro. QTY
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              UOM
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 7%"
            >
              Pro. Unit Sell.
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Total Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Total Sell.
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-right"
              style="width: 6%"
            >
              % Total Sales
            </div>
          </div>
          <div
            v-for="(row, index) in db_line_items2"
            class="top-row"
            :key="`line-item-drag-${index}`"
            :class="`${row.class} ${row.type == 'header' ? '' : ''}`"
          >
            <div
              :id="`line-item-drag-${index}`"
              class="d-flex line-item-drag group_row"
              :class="{ 'item-is-child': row.is_child }"
            >
              <div
                class="font-weight-600 border-left border-right"
                style="width: 100%"
              >
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row.group_name }}
                </div>
              </div>
            </div>
            <div class="d-flex line-item-drag">
              <div
                class="font-weight-600 border-left border-right"
                style="width: 100%"
              >
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row.group_item_name }}
                </div>
              </div>
            </div>
            <div
              v-for="(row, index) in row.item_feature"
              :key="index"
              class="d-flex line-item-drag"
            >
              <div
                class="font-weight-600 text-center border-left border-right"
                style="width: 2%; background: #f5f5f5; z-index: 9"
              >
                <div class="mt-0" style="font-size: 15px">{{ row.id }}.</div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="">
                  {{ row.brand }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="" style="word-break: break-word">
                  {{ row.model }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="">
                  {{ row.part_name }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="">
                  {{ row.product_type }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 4%">
                <div class="">
                  {{ row.currency }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 4%">
                <div class="">
                  {{ row.cost }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 3%">
                <div class="">
                  {{ row.flt }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 4%">
                <div class="">
                  {{ row.xr }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 4%">
                <div class="">
                  {{ row.gear }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 4%">
                <div class="">
                  {{ row.lamp }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 3%">
                <div class="">
                  {{ row.acc }}
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="text-center border-right">{{ row.unit_cost }}</div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="text-center">
                  <span>{{ row.markup }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 6%">
                <div class="text-center border-right">
                  <span>{{ row.unit_selling }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="text-center">
                  <span>{{ row.req_qty }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="text-center border-right">
                  <span>{{ row._pro_req_qty }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 3%">
                <div class="text-center border-right">
                  <span>{{ row.uom }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 7%">
                <div class="text-center border-right">
                  <span>{{ row.pro_unit_selling }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <div class="text-center">
                  <span>{{ row.total_cost }}</span>
                </div>
              </div>
              <div class="text-center border-right" style="width: 5%">
                <span>{{ row.total_selling }}</span>
              </div>
              <div class="text-center border-right" style="width: 6%">
                <div class="text-center">{{ row.total }}</div>
              </div>
            </div>
            <div
              class="d-flex line-item-dra"
              v-if="row.item_feature.length != 0"
            >
              <div class="border-left text-end border-right" style="width: 77%">
                <div class="mt-0 px-1" style="font-size: 15px">
                  <b>Total</b>
                </div>
              </div>
              <div class="border-right" style="width: 7%">
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row.total_cost_value }}
                </div>
              </div>
              <div class="border-right" style="width: 5%">
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row.discounted_total_selling }}
                </div>
              </div>
              <div class="border-right" style="width: 6%">
                <div class="mt-0 px-1" style="font-size: 15px">
                  <b>{{ row.total_selling }}</b>
                </div>
              </div>
              <div class="border-right" style="width: 5%">
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row.total_margin }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center mt-2">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no line items at the moment.
      </div>
    </template>
    <ViewSerialNumber
      v-if="seleceDialog"
      :transaction-detail="transactionDetail"
      :dialogStatus="seleceDialog"
      v-on:close:dialog="seleceDialog = false"
    >
    </ViewSerialNumber>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import ViewSerialNumber from "@/view/pages/partials/View-Serial-Number.vue";
export default {
  name: "visit-line-item",
  props: {
    showPrice: {
      type: Boolean,
      default: false,
    },
    discountLevel: {
      type: String,
      default: "transaction",
    },
    relatedId: {
      type: Number,
      default: 0,
    },
    isEnquiry: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPreventive: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    relatedType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      db_line_items2: [
        {
          group_name: "Group name 1",
          group_item_name: "Group Item 1",
          item_feature: [
            {
              id: 1,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          total_cost_value: 1280,
          total_margin: 34.0,
          discounted_total_selling: 8870.5,
          total_selling: 139520,
        },
        {
          group_name: "Group name 1",
          group_item_name: "Group Item 1",
          item_feature: [
            {
              id: 1,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
            {
              id: 2,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          total_cost_value: 1280,
          total_margin: 34.0,
          discounted_total_selling: 8870.5,
          total_selling: 139520,
        },
      ],
      discount_type_list: [
        { type: "%", value: "2" },
        { type: "$", value: "1" },
      ],
      sno: 1,
      loading: false,
      transactionDetail: {},
      seleceDialog: false,
    };
  },
  methods: {
    optionSerial(row) {
      this.transactionDetail = row;
      this.seleceDialog = true;
    },
    routeToDetailLink(id, type) {
      this.$router.push({
        name: type,
        params: { id },
      });
    },
    routeToDetail(data) {
      let routeName = null;
      if (data.product_type == "equipment") {
        routeName = "client-equipment.detail";
      } else if (data.product_type == "tools") {
        routeName = "equipment.detail";
      } else if (data.product_type == "asset") {
        routeName = "asset-equipment.detail";
      } else if (data.product_type == "goods") {
        routeName = "product.detail";
      } else if (data.product_type == "service") {
        routeName = "service.detail";
      } else if (data.product_type == "parts") {
        routeName = "part.detail";
      }
      if (data && data.product_id && data.product_id > 0) {
        this.$router.replace(
          this.getDefaultRoute(routeName, {
            params: { id: data.product_id },
          })
        );
      }
    },
    get_line_items() {
      this.loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `detail-iteam/${this.relatedId}/line-items`,
          data: {
            type: this.relatedType,
          },
        })
        .then(({ data }) => {
          this.db_line_items = data;
          this.$emit("line-item", this.db_line_items.length);
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    get_col_style(param, child) {
      let width = 55;
      if (param == "product") {
        if (!this.showPrice) {
          width = 80;
        } else if (this.showPrice) {
          width = 55;
          if (this.discountLevel == "transaction") {
            width = 80;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    /*  get_col_style(param, child) {
      let width = 46;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 46;
          if (this.discount_level == "transaction") {
            width = 70;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    }, */
  },
  mounted() {
    this.get_line_items();
  },
  computed: {
    getSN: () => {
      return function (row, index) {
        if (index == 0) {
          this.sno = 1;
          console.log();
        }
        if (row.product_type == "header") {
          this.sno = 1;
        } else {
          this.sno = this.sno + 1;
        }
      };
    },
  },
  components: {
    ViewSerialNumber,
  },
};
</script>
<style scoped>
/* .line-item-drag.top-row {
  width: 2000px;
  overflow-x: auto;
} */

.group_row {
  background-color: #faf3e8;
}
.fixed-td.table tbody td {
  padding: 4px !important;
}
.fixed-td.table thead tr th:first-child {
  position: sticky;
  left: 0;
}
.fixed-td.table thead tr th:nth-child(2) {
  position: sticky;
  left: 46px;
}
.costing-item-header div:first-child {
  position: sticky;
  left: 0;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(2) {
  position: sticky;
  left: 46px;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(3) {
  position: sticky;
  left: 161px;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(4) {
  position: sticky;
  left: 276px;
  background: #f5f5f5;
}
.line-item-drag div:nth-child(1) {
  position: sticky;
  left: 0;
}
.line-item-drag div:nth-child(2) {
  position: sticky;
  left: 46px;
  background: #f5f5f5;
  z-index: 9;
}
.line-item-drag div:nth-child(3) {
  position: sticky;
  left: 161px;
  background: #f5f5f5;
  z-index: 9;
}
.line-item-drag div:nth-child(4) {
  position: sticky;
  left: 276px;
  background: #f5f5f5;
  z-index: 9;
}
.group_row div:first-child {
  position: sticky;
  left: 0;
}
</style>
